// GLOBALS

// Sticky Header
// Set body top padding based on header height
const setBodyTop = () => {
	const body = jQuery('body');
	const headerH = jQuery('header').outerHeight(true);

	body.css({'padding-top': `${headerH}px`});
};

// DOC READY

jQuery($ => {
  // Sticky Header
	// Based on https://codemyui.com/hide-header-navigation-on-scroll-down-and-show-on-scroll-up/
	const stickyHeader = () => {
		const header = $('header');
		const hamburger = $('.hamburger');
		let currentScrollTop, newScrollTop;

   	$(window).on('scroll', () => { 
      const scrollTop = $(window).scrollTop();
			const headerH = header.outerHeight(true);
			
			header.css({'--height': `-${(headerH + 30)}px`});
     
      currentScrollTop = scrollTop;
     
      if (newScrollTop < currentScrollTop && scrollTop > headerH * 2 && !hamburger.hasClass('open')) {
				header.addClass('scroll-down');
      } else if (newScrollTop > currentScrollTop && (scrollTop > headerH)) {
				header.removeClass('scroll-down');
			}
			
      newScrollTop = currentScrollTop;
  	});
  };
  
	stickyHeader();

	// Main Menu
	// Set class to set active state on parent menu items if child is active
	if ($('.navigation .main-menu li.current-menu-item').length) {
		if ($('.navigation .main-menu li.current-menu-item').parents('li').length) {
			$('.navigation .main-menu li.current-menu-item').parents('li').addClass('current-menu-item-parent');
		}
	}

  // MOBILE

  // Hamburger
  // Toggle hamburger
  $('.hamburger').on('click', () => {
    const hamburger = $('.hamburger');
    const menu = $('.navigation.mobile');

    if ($(hamburger).hasClass('open')) {
			resetHamburger();
    } else {
			resetFRSMenu();
			menu.slideDown();
			hamburger.addClass('open');
    }
  });

  // Reset hamburger
	const resetHamburger = (resize = false) => {
		const hamburger = $('.hamburger');
		const menu = $('.navigation.mobile');

		$('.navigation.mobile .main-menu li.menu-item-has-children').each((index, element) => {			
			$(element).children('.sub-menu').slideUp(400, () => {
				$(element).find('.sub-menu-arrow').removeClass('fa-chevron-up').addClass('fa-chevron-down');

				$(element).removeClass('open');

				menu.slideUp(400, () => {
					if (resize === true) {
						setBodyTop();
					}
				});
			});
		});

		hamburger.removeClass('open');
  };
  
  // Main Menu
	// Insert caret after menu items with sub-menus
	$('.navigation.mobile .main-menu li.menu-item-has-children').each((index, element) => {
		const link = $(element).children('a');
		const linkText = link.text();
		const textArray = linkText.split(' ');
		const lastWord = textArray.pop();
		const firstWords = textArray.join(' ');

		link.html(`${firstWords} <span class="no-wrap">${lastWord}<i class="sub-menu-arrow fas fa-chevron-down"></i></span>`.trim());
	});

	// Handle menu item click
	$('.navigation.mobile .main-menu .menu > li > a').on('click', event => {
		const target = $(event.target);
		const targetTag = target.prop('tagName').toLowerCase();
		let trigger = {};

		if (targetTag === 'a') {
			trigger = target;
		} else if (targetTag === 'span' || targetTag === 'i') {
			trigger = target.closest('a');
		}

		const listItem = trigger.closest('li');
		const subMenu = trigger.next('.sub-menu');

		if (subMenu.length) {
			event.preventDefault();
			
			const arrow = trigger.find('i');
			const currentlyOpen = $('.main-menu li.open');

			if (currentlyOpen.attr('data-id') !== listItem.attr('data-id')) {
				currentlyOpen.find('ul').slideUp(200, () => {
					currentlyOpen.removeClass('open');
				});

				currentlyOpen.find('i').removeClass('fa-chevron-up').addClass('fa-chevron-down');
			}

			if (listItem.hasClass('open')) {			
				subMenu.slideUp(200, () => {
					listItem.removeClass('open');
				});

				arrow.removeClass('fa-chevron-up').addClass('fa-chevron-down');
			} else {
				subMenu.slideDown(200, () => {
					listItem.addClass('open');
				});

				arrow.removeClass('fa-chevron-down').addClass('fa-chevron-up');
			}
		}
	});

	// FRS Menu
	// Handle menu toggle
	$('.frs-navigation button').on('click', () => {
		const button = $('.frs-navigation button');
		const arrow = button.find('i');
		const menu = $('.frs-menu');

		if (menu.hasClass('open')) {		
			resetFRSMenu();
		} else {
			resetHamburger();

			menu.slideDown(200, () => {
				menu.addClass('open');
			});

			arrow.removeClass('fa-chevron-down').addClass('fa-chevron-up');
		}
	});

	// Reset FRS menu
	const resetFRSMenu = (resize = false) => {
		const button = $('.frs-navigation button');
		const arrow = button.find('i');
		const menu = $('.frs-menu');

		menu.slideUp(200, () => {
      
			menu.removeClass('open');

			if (resize === true) {
				setBodyTop();
			}
		});

		arrow.removeClass('fa-chevron-up').addClass('fa-chevron-down');
	};

  // DESKTOP
  
	// Main Menu
	// Handle menu item click
	$('.navigation.desktop .main-menu .menu > li > a').on('click', event => {
		const target = $(event.target);
		const targetTag = target.prop('tagName').toLowerCase();
		let trigger = {};

		if (targetTag === 'a') {
			trigger = target;
		} else if (targetTag === 'span') {
			trigger = target.closest('a');
		}
		
		const listItem = trigger.closest('li');
		const subMenu = trigger.next('.sub-menu');

		if (subMenu.length) {
			event.preventDefault();

			const subMenuToggle = () => {
				if (listItem.hasClass('open')) {
					subMenu.slideUp(200, () => {
						listItem.removeClass('open');
						if (listItem.is(':hover')) {
							listItem.prev().addClass('adjacent-hover');
						} else {
							listItem.prev().removeClass('adjacent-hover');
						}
					});
				} else {
					subMenu.slideDown(200);
					listItem.addClass('open');
					listItem.trigger('hover');
				}
			};

			const currentlyOpen = $('.main-menu li.open');

			if (currentlyOpen.length) {
				if (currentlyOpen.attr('data-id') !== listItem.attr('data-id')) {
					currentlyOpen.find('ul').slideUp(200, () => {
						currentlyOpen.removeClass('open');
						currentlyOpen.prev().removeClass('adjacent-hover');
	
						subMenu.slideDown(200);
						listItem.addClass('open');
						listItem.trigger('hover');
					});
				} else {
					subMenuToggle();
				}
			} else {
				subMenuToggle();
			}
		}
	});
	
	// Toggle vertical line to left of hovered menu item
	$('.navigation.desktop .main-menu li').on('mouseover', event => {
		const target = $(event.target);
		const listItem = target.parents('li');

		if (!listItem.hasClass('open')) {
			listItem.prev().addClass('adjacent-hover');
		}
	});

	$('.navigation.desktop .main-menu li').on('mouseout', event => {
		const target = $(event.target);
		const listItem = $(target).parents('li');

		if (!listItem.hasClass('open')) {
			listItem.prev().removeClass('adjacent-hover');
		}
	});

  // Utility Menu
  // Search
  // Handle search icon click	
	$('.navigation.desktop .header-search button').on('click', event => {
		event.preventDefault();
		
		const search = $('.navigation.desktop .header-search');
		const input = $(search).find('input[type="text"]');
		const button = $(search).find('button');
		const icon = $(button).find('svg use');

		if ($(search).hasClass('open')) {
			$(icon).attr('xlink:href', '#search-icon');
			$(input).animate({'width': 39, 'padding-right': 0}, 400, 'swing', () => {
				$(input).blur();
				$(input).css({'display': 'none'});
				$(search).removeClass('open');
			});
		} else {
			$(icon).attr('xlink:href', '#search-icon-close');
			$(input).css({display: 'block'});
			$(input).animate({'width': 300, 'padding-right': 60}, 400, 'swing', () => {
				$(search).addClass('open');
				$(input).focus();
			});
		}
	});

	// RESIZE

	// Try / catch
	// iOS 13 and lower do not support addEventListener
	try {
		// When window is sized down across the XL breakpoint
		window.matchMedia('(max-width: 1199px)').addEventListener('change', event => { 
			if (event.matches) {
				// Reset sticky header
				$('header').removeClass('scroll-down');
				
				// Reset body top margin based on header height
				setBodyTop();
			}
		}); 
		
		// When window is sized up across the XL breakpoint
		window.matchMedia('(min-width: 1200px)').addEventListener('change', event => { 
			if (event.matches) {
				// Reset hamburger menu
				resetHamburger(true);

				// Reset FRS menu
				resetFRSMenu(true);

				// Reset sticky header
				$('header').removeClass('scroll-down');
			}
		}); 
	} catch (error1) {
		try {
			window.matchMedia('(max-width: 1199px)').addListener(event => { 
				if (event.matches) {
					// Reset sticky header
					$('header').removeClass('scroll-down');
					
					// Reset body top margin based on header height
					setBodyTop();
				}
			}); 
			
			// When window is sized up across the XL breakpoint
			window.matchMedia('(min-width: 1200px)').addListener(event => { 
				if (event.matches) {
					// Reset hamburger menu
					resetHamburger(true);
	
					// Reset FRS menu
					resetFRSMenu(true);
	
					// Reset sticky header
					$('header').removeClass('scroll-down');
				}
			}); 
		} catch (error2) {
			console.error(error2);
		}
	}
});

// WINDOW LOAD

const headerWindowLoad = () => {
	// Sticky Header
	// Run function to set body top padding based on header height
	setBodyTop();

	// Main Menu Identifiers
	// Add data attributes for unique menu IDs
	// WP's automatically included IDs don't work in the mobile menu, since they're already used in the desktop menu and IDs must be unique on a page
	jQuery('.main-menu li').each((index, element) => {
		jQuery(element).attr('data-id', index);
	});
};

// If IE, run on doc ready
if (window.document.documentMode) {
	headerWindowLoad();
}

// Everyone else run on window load
jQuery(window).on('load', () => {
	headerWindowLoad();
});