jQuery($ => {
	// Add class if tab key is pressed (for use with focus styles)
	// Based on https://codepen.io/stowball/pen/LNZyrK
	$(document).on('keydown', event => {
		const keycode = (event.keyCode ? event.keyCode : event.which);

		if (keycode === 9) { // Tab key
			$('body').addClass('keyboard-focus');
		}
	});
});