// GLOBAL

// Masonry
// Options
const masonryOptions = {
  itemSelector: '.grid-post',
  columnWidth: '.post-sizer',
  percentPosition: true,
  gutter: 24,
  horizontalOrder: true,
};

// Init Masonry
const masonryGrid = jQuery('.post-grid').masonry(masonryOptions);

// Homepage post grid
// Handle window size change
const breakXLDown = window.matchMedia('(max-width: 1199px)');
const breakXLUp = window.matchMedia('(min-width: 1200px)');

// Try / catch
// iOS 13 and lower do not support addEventListener
try {
  // When window is sized down across the XL breakpoint
  breakXLDown.addEventListener('change', event => { 
    if (event.matches) {
      if (('.home-post-grid').length) {
        // Init Masonry instance on homepage post grid
        jQuery('.home-post-grid .post-grid').masonry(masonryOptions);
      }
    }
  });

  // When window is sized up across the XL breakpoint
  breakXLUp.addEventListener('change', event => { 
    if (event.matches) {
      if (('.home-post-grid').length) {
        // Destroy Masonry instance on homepage post grid
        jQuery('.home-post-grid .post-grid').masonry().masonry('destroy');
      }
    }
  }); 
} catch (error1) {
  try {
    // When window is sized down across the XL breakpoint
    breakXLDown.addListener(event => { 
      if (event.matches) {
        if (('.home-post-grid').length) {
          // Init Masonry instance on homepage post grid
          jQuery('.home-post-grid .post-grid').masonry(masonryOptions);
        }
      }
    });
    
    // When window is sized up across the XL breakpoint
    breakXLUp.addListener(event => { 
      if (event.matches) {
        if (('.home-post-grid').length) {
          // Destroy Masonry instance on homepage post grid
          jQuery('.home-post-grid .post-grid').masonry().masonry('destroy');
        }
      }
    });
  } catch (error2) {
    console.error(error2);
  }
}

// WINDOW LOAD

const gridWindowLoad = () => {
  // If window is XL breakpoint or larger
  if (window.matchMedia('(min-width: 1200px)').matches) {
    jQuery('.post-grid').each((index, element) => {
      if (jQuery(element).parents('.home-post-grid')) {
        // Destroy Masonry instance on homepage post grid
        jQuery('.home-post-grid .post-grid').masonry().masonry('destroy');
        
      } else {
        // Redraw for proper spacing
        masonryGrid.masonry('layout');
      } 
    });
  } else {
    // Redraw for proper spacing
    masonryGrid.masonry('layout');
  }   
  
  // On FacetWP load
  // Reload and redraw Masonry grid items
  jQuery(document).on('facetwp-loaded', () => {
    masonryGrid.masonry().masonry('reloadItems');
    masonryGrid.masonry().masonry('layout');
  });

  // Pagination
  // Scroll page to paginated element
  if (jQuery('.pagination').length) {
    const pagination = jQuery('.pagination');
    const queryVars = window.location.search;

    if (queryVars.indexOf('paging') != -1) {
      const top = pagination.closest('section').offset().top - 30;

      jQuery('html, body').animate({ scrollTop: top }, 200, () => {
        setTimeout(() => {
          jQuery('header').addClass('scroll-down');
        }, 30);
      });
    }
  }
};

// If IE, run on doc ready
if (window.document.documentMode) {
  gridWindowLoad();
}

// Everyone else run on window load
// On window load
jQuery(window).on('load', () => {
  gridWindowLoad();
});