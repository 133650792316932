const loadingWindowLoad = () => {
  jQuery('.loading').fadeOut(150);
};

// If IE, run on doc ready
if (window.document.documentMode) {
  loadingWindowLoad();
}

// Everyone else run on window load
jQuery(window).on('load', () => {
  loadingWindowLoad();
});