jQuery($ => {
  // Set form anchor ID
  if (jQuery('.form-group').length) {
    jQuery('.form-group').each((index, element) => {
      const anchor = jQuery(element).find('.gform_anchor');
      const anchorID = jQuery(anchor).attr('id');

      jQuery(anchor).attr('id', '');
      jQuery(element).find('.form-anchor').attr('id', anchorID);
    });
  }

  // Select Field
  // Change text color on selected option change
  $('.gform_wrapper select').on('change', event => {
    $(event.target).css({'color': '#000000'});
  });

  // Date Picker
  // Set identifying class on parent div for styling purposes
  if ($('.gform_wrapper').length) {
    $('.gform_wrapper ginput_container_date > .datepicker').each((index, element) => {
      $(element).parents('div').addClass('ginput_container_datepicker');
    });
  }
});

// WINDOW LOAD

const formsWindowLoad = () => {
  if (jQuery('.form-group').length) {
    const hashTag = window.location.hash;

    // If post-form submit
    if (hashTag.indexOf('#gf_') != -1) {
      setTimeout(() => {
        // Hide header
        jQuery('header').addClass('scroll-down');
      }, 30);
    } 
  }
};

// If IE, run on doc ready
if (window.document.documentMode) {
  formsWindowLoad();
}

// Everyone else run on window load
// On window load
jQuery(window).on('load', () => {
  formsWindowLoad();
});