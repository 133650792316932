// GLOBALS

// Search Results
// Set and remove active class on sort links for styling purposes
const searchSortActive = () => {
  // Current GET variables
  const get = FWP_HTTP.get;
  // Current sort setting
  const sort = get._sort;
  // Sort container
  const sortLinks = jQuery('.sort-by-container');

  // If sorted by date
  if (sort === 'date') {
    // Set date link active
    jQuery(sortLinks).find('.date').siblings('a').removeClass('active');
    jQuery(sortLinks).find('.date').addClass('active'); 
  } else {
    // Set default link active
    jQuery(sortLinks).find('.default').addClass('active');
    jQuery(sortLinks).find('.default').siblings('a').removeClass('active');
  }
};

jQuery($ => {
  // GLOBAL
  
  // Select Field
  // Change text color on selected option change  
  $(document).on('change', '.facetwp-facet select', event => {
    $(event.target).css({'color': '#000000'});
  });

  // Pagination
  // Scroll to top on page change
  // Based on https://gist.facetwp.com/gist/gist-971940882f87a5ce0bdcdfd83ae8a9ae/
  $(document).on('facetwp-refresh', () => {
    if (FWP.soft_refresh == true) {
      FWP.enable_scroll = true;
    } else {
      FWP.enable_scroll = false;
    }
  });

  $(document).on('facetwp-loaded', () => {
    if (FWP.enable_scroll == true) {
      const top = $('.search-bar').offset().top;

      $('html, body').animate({ scrollTop: top }, 150, () => {
        setTimeout(() => {
          $('header').addClass('scroll-down');
        }, 30);
      });
    }
  });

  
  $(document).on('facetwp-loaded', () => {
    // Search Results
    if ($('.search-results').length) {
      // Set and remove active class on sort links for styling purposes
      searchSortActive();
    }
  });
});

// WINDOW LOAD

const facetWPWindowLoad = () => {
  // Select Field
  // Change text color on selected option change
  if (jQuery('.facetwp-facet select').length) {
    jQuery('.facetwp-facet select').each((index, element) => {
      if (jQuery(element).val() != '') {
        jQuery(element).css({'color': '#000000'});
      }
    });
  }

  // If full post grid exists
  if (jQuery('.full-post-grid').length) {
    const queryVars = window.location.search;

    if (queryVars.indexOf('_') != -1) {
      const header = jQuery('header');
      const headerH = header.outerHeight(true);
      const grid = jQuery('.full-post-grid');
      const top = grid.offset().top + headerH;

      jQuery('html, body').animate({ scrollTop: top }, 200, () => {
        setTimeout(() => {
          jQuery('header').addClass('scroll-down');
        }, 30);
      });
    }
  }

  // Search Results
  if (jQuery('.search-results').length) {
    // Set and remove active class on sort links for styling purposes
    searchSortActive();
  }
};

// If IE, run on doc ready
if (window.document.documentMode) {
  facetWPWindowLoad();
}

// Everyone else run on window load
// On window load
jQuery(window).on('load', () => {
  facetWPWindowLoad();
});